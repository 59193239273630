<template>
    <v-container v-if="!halted" class="amp-module-page wide">
        <a-form ref="form" :auto-submit="!isModuleBusy" @auto-submit="autoSave">
            <v-card
                class="pa-4"
                :class="{
                    'pa-5 px-6': $vuetify.breakpoint.mdAndUp
                }"
            >
                <v-row v-if="hasPendingAutofill">
                    <v-col>
                        <a-alert type="warning">
                            <v-progress-circular
                                indeterminate
                                size="14"
                                width="2"
                                class="mx-2"
                            />
                            The auto-selecting videos is still in progress ...
                        </a-alert>
                    </v-col>
                </v-row>
                <v-row v-if="hasPublishIssues">
                    <v-col>
                        <a-alert
                            v-for="(issue, i) in publishIssues"
                            :key="i"
                            :message="issue.message"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-text-input
                            ref="title"
                            v-model="video.title"
                            :loading="isLoading"
                            label="Title"
                            rules="required|max:100|youtube_video_title"
                            rows="1"
                            auto-grow
                            textarea
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-content-editor
                            ref="description"
                            v-model="video.description"
                            :loading="isLoading"
                            :no-first-person="false"
                            label="Description"
                            rules="required|words:0,45"
                            :counter-value="getWordsCounter(45)"
                            one-line
                        />
                    </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.smAndDown">
                    <v-col class="py-0 col-12">
                        <a-slideshow
                            ref="slideshow"
                            label="Preview"
                            :template="video.video_template"
                            :active="slide"
                            :portrait="isPortrait"
                            delimiter-icon="film"
                            link-entity="scene"
                            allow-stock-video-search
                            class="pb-4"
                            :loading="isLoading"
                            @slide="sync"
                            @zoom="handleFullScreen"
                        />

                        <alert-videos-filled
                            :loading="isLoading"
                            :video="video"
                            @click.native="navigateToSlide"
                        />

                        <amp-validation
                            :input="$refs.slideshow"
                            :loading="isLoading"
                            parse
                            limit="5"
                            class="pointing-validation mt-4"
                            @click.native="navigateToSlide"
                        />
                        <v-container class="pa-0">
                            <v-row>
                                <v-col class="text-right">
                                    <v-btn
                                        :disabled="isLoading"
                                        text
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        :block="$vuetify.breakpoint.smAndDown"
                                        @click="changeTemplate"
                                    >
                                        <v-icon left>folder-open</v-icon>
                                        Change Template
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
                <v-row class="video-container">
                    <v-col class="py-0 col-md-6 col-lg-6 col-sm-12">
                        <v-container v-if="canHaveLogo" class="pa-0">
                            <v-row>
                                <v-col>
                                    <a-toggle
                                        v-model="useLogo"
                                        :class="{
                                            'mt-0': $vuetify.breakpoint.mdAndUp,
                                            'mt-6': $vuetify.breakpoint
                                                .smAndDown
                                        }"
                                        label="Display company logo overlay in video"
                                        :disabled="!companyHasLogo"
                                        @change="toggleLogo"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                        <media-form
                            ref="mediaForm"
                            :template="video.video_template"
                            entity="scene"
                            entity-icon="film"
                            class="pa-0"
                            @activate="sync"
                        />
                        <v-container class="pa-0 pt-6">
                            <v-row dense>
                                <v-col class="flex-grow-1">
                                    <a-select-input
                                        v-model="video.video_voice_id"
                                        :loading="isLoading"
                                        label="Select Voice"
                                        :items="voices"
                                        item-text="voice_name"
                                        item-value="id"
                                    />
                                </v-col>
                                <v-col class="pl-4 pt-3 flex-grow-0">
                                    <audio-player-chip :src="activeVoiceSrc" />
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col class="flex-grow-1">
                                    <a-select-input
                                        v-model="video.video_audio_clip_id"
                                        :loading="isLoading"
                                        label="Select Backgound Music"
                                        :items="clips"
                                        item-text="name"
                                        item-value="id"
                                    />
                                </v-col>
                                <v-col class="pl-4 pt-3 flex-grow-0">
                                    <audio-player-chip :src="activeClipSrc" />
                                </v-col>
                            </v-row>
                            <a-combobox
                                v-model="video.tags_array"
                                :loading="isLoading"
                                label="Tags"
                                rules="required|max_items:10"
                                limited
                                limited-hint="No more than 10 tags are permitted"
                                chips
                                deletable-chips
                                clearable
                                multiple
                                append-icon
                                hint="Hit enter to add a tag after typing"
                                persistent-hint
                            />
                            <a-toggle
                                v-if="!isPortrait"
                                v-model="video.add_ident"
                                :loading="isLoading"
                                label="Show UBC News Intro"
                                class="pt-8 ma-0"
                            />
                        </v-container>
                    </v-col>
                    <v-col
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="py-0 col-md-6 col-lg-6"
                    >
                        <sticky-media
                            relative-element-selector=".video-container"
                            :offset="{ top: 90, bottom: 90 }"
                            :enabled="
                                !isFullScreen && !$vuetify.breakpoint.mobile
                            "
                        >
                            <a-slideshow
                                ref="slideshow"
                                label="Preview"
                                :template="video.video_template"
                                :active="slide"
                                :portrait="isPortrait"
                                :loading="isLoading"
                                delimiter-icon="film"
                                link-entity="scene"
                                class="pb-4"
                                allow-stock-video-search
                                @slide="sync"
                                @zoom="handleFullScreen"
                            />

                            <alert-videos-filled
                                :loading="isLoading"
                                :video="video"
                                @click.native="navigateToSlide"
                            />

                            <amp-validation
                                :input="$refs.slideshow"
                                :loading="isLoading"
                                parse
                                limit="5"
                                class="pointing-validation mt-4"
                                @click.native="navigateToSlide"
                            />
                            <v-container class="pa-0">
                                <v-row>
                                    <v-col class="text-right">
                                        <v-btn
                                            :disabled="isLoading"
                                            text
                                            :large="$vuetify.breakpoint.mdAndUp"
                                            :block="
                                                $vuetify.breakpoint.smAndDown
                                            "
                                            @click="changeTemplate"
                                        >
                                            <v-icon left>folder-open</v-icon>
                                            Change Template
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </sticky-media>
                    </v-col>
                </v-row>
            </v-card>
        </a-form>
        <warn-portrait-dialog v-if="!isPortrait" @confirm="goToPortrait" />
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import {
    Endpoint,
    MediaModuleEndpoint,
    VideoTemplateActions,
    WordsCounter
} from '@/mixins';
import { InjectReactive, ProvideReactive, Watch } from '@/utils/decorators';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { ASlideshow, MediaForm, StickyMedia } from '@/components/ASlideshow';
import { AudioPlayerChip } from '@/components/AudioPlayerChip';

import { ACombobox } from '@/components/AForm/Inputs/ACombobox';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AToggle } from '@/components/AForm/Inputs/AToggle';

import { AlertVideosFilled } from '@/components/VideoAutofill';

import WarnPortraitDialog from './WarnPortraitDialog.vue';

import type { NavigationGuardNext, Route } from 'vue-router';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import type { AmpModules, Announcement } from '@/types/Announcement';
import type { MediaFile } from '@/types/MediaFile';
import type { ModuleLink } from '@/types/ModuleLink';
import {
    VideoStatus,
    type VideoBox,
    type VideoPr,
    type VideoVoice
} from '@/types/Video';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AAlert,
        AudioPlayerChip,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        MediaForm,
        AForm,
        ATextInput,
        AContentEditor,
        ASelectInput,
        ACombobox,
        AToggle,
        ASlideshow,
        StickyMedia,
        AlertVideosFilled,
        WarnPortraitDialog
    }
})
export default class Video extends mixins(
    Endpoint,
    MediaModuleEndpoint,
    VideoTemplateActions,
    WordsCounter
) {
    $refs!: {
        form: InstanceType<typeof AForm>;
        mediaForm: InstanceType<typeof MediaForm>;
        title: InstanceType<typeof ATextInput>;
        description: InstanceType<typeof ATextInput>;
        slideshow: InstanceType<typeof ASlideshow>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @InjectReactive({
        from: 'announcement',
        default() {
            return {
                video_stock_media_autofill: null
            };
        }
    })
    announcement!: Partial<Announcement>;

    @InjectReactive({
        from: 'isModuleBusy',
        default() {
            return false;
        }
    })
    isModuleBusy!: boolean;

    @Watch('modules', { deep: true })
    onModulesChanged() {
        this.onMounted();
    }

    @Watch('slide')
    onSlideChange() {
        this.syncForm(this.slide);
    }

    @ProvideReactive()
    get user_id() {
        return this.video.user_id;
    }

    slide = 0;

    video: Partial<VideoPr> = {};

    endpoint = '/video_prs/edit';

    isSaving = false;

    isFullScreen = false;

    canGeneratePreview = true;

    useLogo = false;

    shouldWarnAboutPortrait = false;

    halted = false;

    get link(): ModuleLink[] {
        return [
            {
                type: 'primary',
                label: 'Review',
                to: this.reviewLink
            }
        ];
    }

    get moduleId() {
        return this.isPortrait
            ? this.modules?.portrait_video_pr_id
            : this.modules?.video_pr_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get hasVoices() {
        return this.voices.length > 1;
    }

    get voices() {
        return this.addNoVoiceEntry(
            this.video.video_template?.video_voices || []
        );
    }

    get activeVoiceSrc() {
        const active = this.voices.find(
            voice => voice.id === this.video.video_voice_id
        );

        if (active) {
            return active.route;
        }

        return '';
    }

    get hasAudioClips() {
        return this.clips.length > 0;
    }

    get clips() {
        const clips = (this.video.video_template?.video_audio_clips || []).map(
            (clip, i) => {
                return {
                    ...clip,
                    name: `Audio Clip ${i + 1}`
                };
            }
        );

        clips.unshift({
            id: null,
            name: 'No Music',
            filename: '',
            is_premium: false,
            is_system_media: false,
            route: ''
        });

        return clips;
    }

    get activeClipSrc() {
        const active = this.clips.find(
            clip => clip.id === this.video.video_audio_clip_id
        );

        if (active) {
            return active.route;
        }

        return '';
    }

    get hasPublishIssues() {
        return this.publishIssues.length > 0;
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            [
                this.announcementId,
                'publish',
                this.isPortrait ? 'portrait_video' : 'video'
            ].join('-')
        );
    }

    get companyHasLogo() {
        return Boolean(
            this.video.announcement?.company?.media_resources[0]?.media_file.id
        );
    }

    get canHaveLogo() {
        return this.video.video_template?.video_boxes.some(
            box => box.video_media_box?.is_logo_box
        );
    }

    get reviewLink() {
        return [
            `/announcements/review/${this.announcementId}/video`,
            this.isPortrait && 'portrait'
        ]
            .filter(Boolean)
            .join('/');
    }

    get portraitPath() {
        return `/announcements/edit/${this.announcementId}/video/portrait`;
    }

    get hasPendingAutofill() {
        return this.announcement.video_stock_media_autofill === '[]';
    }

    get isValid() {
        return this.$refs.form.isValidated ? this.$refs.form.isValid : true;
    }

    get isPortrait() {
        return this.$route.params.mode === 'portrait';
    }

    get hasPortrait() {
        return !!this.modules.portrait_video_pr_id;
    }

    get canSeePortrait() {
        return Boolean(this.video.user?.has_ai_features);
    }

    get landscapeSource(): string {
        const preserved = this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-landscape-video`
        )[0];

        if (preserved) {
            return preserved.message;
        }

        return '';
    }

    get portraitSource(): string {
        const preloaded = this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-portrait-video`
        )[0];

        if (preloaded) {
            return preloaded.message;
        }

        return '';
    }

    get isInitialized() {
        return Boolean(this.video.id);
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();
            this.setPreSendHook();

            if (this.isPortrait) {
                this.consumePortrait();
            } else {
                this.load();

                this.preloadPortrait();
            }
        } else {
            this.askToCreate();
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    setPreSendHook(isSet = true) {
        this.$emit('pre-send', isSet ? this.preSend.bind(this) : null);
    }

    askToCreate() {
        if (this.hasPendingAutofill) {
            // refresh in 10 seconds
            setTimeout(() => this.reload(), 10000);
        } else if (this.modules && !this.moduleId) {
            this.$emit('create', this.endpoint);
        }
    }

    onData(data: { video: VideoPr }) {
        if (data.video) {
            return this.commit(data);
        } else {
            this.review();
        }
    }

    async commit(data: { video: VideoPr }) {
        if (data.video) {
            this.softCommit(data.video);

            this.protectRoute();

            this.syncLogoToggle();

            this.emitLinks();

            this.ensureRendering();
        }

        await this.setSaved();

        this.initAutoCopy();

        return data;
    }

    softCommit(data: VideoPr) {
        if (this.isInitialized) {
            const softProperties: Array<keyof VideoPr> = [
                'is_editable',
                'is_live',
                'is_publishable',
                'status_string',
                'status'
            ];

            (Object.keys(data) as Array<keyof VideoPr>)
                .filter(key => softProperties.includes(key))
                .forEach(key => {
                    this.$set(this.video, key, data[key]);
                });
        } else {
            this.video = data;
        }
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);
        this.setPreSendHook(false);

        if (this.halted) {
            return next();
        }

        if (this.$refs.form.isDirty) {
            return this.save(false).then(() => this.canLeaveRoute(to, next));
        } else {
            return this.canLeaveRoute(to, next, true);
        }
    }

    async canLeaveRoute(
        to: Route,
        next: NavigationGuardNext,
        needsValidation = false
    ) {
        if (
            !this.isPortrait &&
            this.shouldWarnAboutPortrait &&
            to.path !== this.portraitPath
        ) {
            this.warnAboutPortrait();

            return next(false);
        }

        if (this.video.is_editable && to.path === this.reviewLink) {
            if (needsValidation) {
                await this.$refs.form.validate();
            }

            if (!this.isValid) {
                this.notifyInvalid();

                this.emitLinks(true);
                // no reason to switch to preview
                return next(false);
            }
        }

        return next();
    }

    async save(foreground = true) {
        this.setSaving();

        return this.setData(foreground)
            .then(() => {
                // if video became not-editable
                if (foreground) {
                    this.protectRoute();
                }
            })
            .catch(() => {
                if (foreground) {
                    this.notifyError();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    async autoSave() {
        return this.setData(false).catch(error => {
            if (!error.isIntercepted) {
                this.$store.dispatch('notification/error', error);
            }
        });
    }

    async setData(foreground = true) {
        this.canGeneratePreview = await this.revalidate(foreground);

        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => data)
            .then(({ data }) => this.commit(data));
    }

    getDataToSave() {
        return {
            id: this.video.id,
            video_template_id: this.video.video_template_id,
            video_audio_clip_id: this.video.video_audio_clip_id,
            video_voice_id: this.video.video_voice_id,
            add_ident: this.isPortrait ? false : this.video.add_ident, // we don't want UBC intro for P-Videos
            tags: this.video.tags_array,
            title: this.video.title,
            description: this.video.description,
            media_resources: this.collectMediaResources(
                this.video.video_template
            ),
            video_captions: this.collectVideoCaptions(
                this.video.video_template
            ),
            generate_preview: this.canGeneratePreview
        };
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    async setSaved() {
        return this.$refs.form?.reset();
    }

    navigateToSlide(e: PointerEvent) {
        const slide = (e.target as HTMLElement).dataset.slide;

        if (slide) {
            this.sync(Number(slide));
        }
    }

    sync(slide: number) {
        this.slide = slide;
    }

    syncForm(slide: number) {
        this.$nextTick(() => {
            this.$refs.mediaForm.scrollToGroup(slide);
        });
    }

    addNoVoiceEntry(voices: Partial<VideoVoice>[]) {
        voices.unshift({
            id: null,
            voice_name: 'No Voiceover',
            route: ''
        });

        return voices;
    }

    async revalidate(foreground = true) {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-video`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid && foreground) {
            this.notifyInvalid();
        }

        return isValid;
    }

    review() {
        this.$router.push(this.reviewLink);
    }

    async changeTemplate() {
        if (this.$refs.form?.isDirty) {
            await this.save(false);
        }
        // skip portrait video checking
        this.shouldWarnAboutPortrait = false;

        return this.$router.push(
            `/announcements/edit/${this.announcementId}/video/from-template`
        );
    }

    handleFullScreen(isFullScreen: boolean) {
        this.isFullScreen = isFullScreen;
    }

    protectRoute() {
        if (!this.video.is_editable) {
            this.halt();

            this.$nextTick(() => {
                this.review();
            });
        }
    }

    emitLinks(reset = false) {
        if (reset) {
            this.$emit('links', []);
        }

        this.$nextTick(() => {
            this.$emit('links', this.link);
        });
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(() => this.revalidate())
            .catch(() => {
                this.notifyError();

                return false;
            })
            .finally(this.setSaving.bind(this, false));
    }

    preSend() {
        if (this.shouldWarnAboutPortrait) {
            this.warnAboutPortrait();

            return false;
        }

        return true;
    }

    notifyInvalid() {
        this.$store.dispatch(
            'notification/warn',
            'Video saved successfully. Please check the form for errors.'
        );
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save Video. Please check the form for errors.'
        );
    }

    hasLogoApplied(video: Partial<VideoPr>) {
        return Boolean(
            video.video_template?.video_boxes.some(box => {
                if (box.video_media_box?.is_logo_box) {
                    const [blankMediaFileId]: number[] = JSON.parse(
                        box.video_media_box?.media_file_ids || '[]'
                    );

                    if (blankMediaFileId) {
                        return (
                            box.video_media_box.media_resource.media_file_id !==
                            blankMediaFileId
                        );
                    }
                }

                return false;
            })
        );
    }

    toggleLogo(isOn = false) {
        const logoBoxes = this.video.video_template?.video_boxes.filter(
            box => box.video_media_box?.is_logo_box
        );

        logoBoxes?.forEach(box => {
            if (box.video_media_box) {
                const [blankMediaFileId]: number[] = JSON.parse(
                    box.video_media_box?.media_file_ids || '[]'
                );

                if (blankMediaFileId) {
                    this.setBoxMediaFile(
                        box,
                        box.video_media_box?.media_files.find(file =>
                            isOn
                                ? file.id !== blankMediaFileId
                                : file.id === blankMediaFileId
                        )
                    );
                }
            }
        });
    }

    syncLogoToggle() {
        this.useLogo = this.hasLogoApplied(this.video);
    }

    setBoxMediaFile(box: VideoBox, file?: MediaFile) {
        if (box.video_media_box && file) {
            box.video_media_box.media_resource.media_file = file;
            // validation needs this
            box.video_media_box.media_resource.media_file_id = file.id;
        }
    }

    reload() {
        // resets isInitialized flag, so new data can be applied
        this.video.id = 0;

        this.$emit('reload');
    }

    initAutoCopy() {
        if (this.isPortrait) {
            if (this.isNewPortraitVideo(this.video)) {
                this.proceedCopy();
            }
        } else {
            this.preserveLandscapeVideo();
        }
    }

    preserveLandscapeVideo() {
        if (!this.isPortrait) {
            this.$store.dispatch('broadcast/broadcast', {
                source: `${this.announcementId}-landscape-video`,
                clean: true, // every new save should reset previous value
                message: JSON.stringify(this.video)
            });
        }
    }

    proceedCopy() {
        if (this.landscapeSource) {
            try {
                const landscape: VideoPr = JSON.parse(this.landscapeSource);

                this.resetSource('landscape');

                this.copyVideoDetails(landscape, this.video);

                this.toggleLogo(this.hasLogoApplied(landscape));

                this.syncLogoToggle();

                this.$nextTick(() => {
                    // enable autosave
                    this.$refs.title.$refs.provider.setFlags({
                        dirty: true,
                        changed: true,
                        touched: true
                    });
                    // changing values directly needs manual re-validation
                    this.$refs.slideshow.$refs?.provider?.validate();
                });

                return true;
            } catch {
                /* silently */
            }
        }

        return false;
    }

    preloadPortrait() {
        if (!this.isPortrait && this.modules.portrait_video_pr_id) {
            this.$http
                .get(`/video_prs/edit/${this.modules.portrait_video_pr_id}`)
                .then(({ data }) => data.data)
                .then(({ video }: { video: VideoPr }) => {
                    this.$store.dispatch('broadcast/broadcast', {
                        source: `${this.announcementId}-portrait-video`,
                        clean: true,
                        message: JSON.stringify(video)
                    });

                    this.shouldWarnAboutPortrait =
                        this.isNewPortraitVideo(video);
                });
        }
    }

    async consumePortrait() {
        if (this.portraitSource) {
            try {
                const portrait: VideoPr = JSON.parse(this.portraitSource);

                this.resetSource('portrait');

                return Promise.resolve(
                    this.onData({
                        video: portrait
                    })
                ).then(() => {
                    // navigating to the route directly will create new Video instanse with isLoading=true
                    // we need to cheat it by turning off
                    this.setLoading(false);
                });
            } catch {
                /* silently */
            }
        }

        return this.load();
    }

    isNewPortraitVideo(video: Partial<VideoPr>) {
        // every new Portrait video will be missing a title
        return !video.title;
    }

    warnAboutPortrait() {
        this.$store.dispatch('modal/open', 'you-havent-seen-portrait-video');
    }

    goToPortrait() {
        this.shouldWarnAboutPortrait = false;

        this.$router.push(this.portraitPath);
    }

    resetSource(source: 'landscape' | 'portrait') {
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-${source}-video`
        );
    }

    halt(halt: boolean = true) {
        this.halted = halt;
    }

    ensureRendering() {
        // triggers video-rendering for newly created, valid video
        if (this.video.status === VideoStatus.Draft) {
            this.$nextTick(() => {
                this.autoSave();
            });
        }
    }
}
</script>
